import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InfoPopover } from '../../../../../InfoPopover';

export const RideToCenter: FC<{ region: string; rideToCenter: number }> = ({
  region,
  rideToCenter,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <InfoPopover
        label={t('project.overview.minutes', '{{value}} mins', {
          value: rideToCenter,
        })}
        popoverProps={{
          content: t(
            'project.overview.info.ride_to_center',
            'The time it takes to get to the center of {{region}} by car on a workday.',
            { region },
          ),
        }}
      />
    </div>
  );
};
