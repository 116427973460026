import { CountryEnum } from './api/enums';

export type VatRuleValue = {
  rule: number;
  value: number;
};

export type VatRules = {
  [key in CountryEnum]: VatRuleValue[];
};

export const VAT_RULES: VatRules = {
  slovakia: [
    {
      rule: 119,
      value: 1.2,
    },
    {
      rule: 120,
      value: 1.2,
    },
  ],
  czechia: [
    {
      rule: 119,
      value: 1.12,
    },
    {
      rule: 120,
      value: 1.21,
    },
  ],
  uk: [
    {
      rule: 119,
      value: 1.2,
    },
    {
      rule: 120,
      value: 1.2,
    },
  ],
  us: [
    {
      rule: 119,
      value: 1,
    },
    {
      rule: 120,
      value: 1,
    },
  ],
  latvia: [
    {
      rule: 119,
      value: 1.21,
    },
    {
      rule: 120,
      value: 1.21,
    },
  ],
  austria: [
    {
      rule: 119,
      value: 1.2,
    },
    {
      rule: 120,
      value: 1.2,
    },
  ],
  germany: [
    {
      rule: 119,
      value: 1.19,
    },
    {
      rule: 120,
      value: 1.19,
    },
  ],
};

type GoogleMapsLibraries = (
  | 'drawing'
  | 'geometry'
  | 'localContext'
  | 'places'
  | 'visualization'
)[];
export const GOOGLE_MAPS_LIBRARIES: GoogleMapsLibraries = [
  'drawing',
  'geometry',
];

export const DEFAULT_BOUNDS = {
  north: 85,
  south: -85,
  west: -180,
  east: 180,
};

export const CZECHIA_BOUNDS = {
  west: 12.2401111182,
  south: 48.5553052842,
  east: 18.8531441586,
  north: 51.1172677679,
};

export const SLOVAKIA_BOUNDS = {
  west: 16.840547,
  east: 22.5181376482,
  south: 47.7184288601,
  north: 49.6115740017,
};
