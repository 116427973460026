import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  Page,
  SaleProgress,
  FloorOverview,
  Price,
  SaleDetail,
  SaleOverview,
} from '../components';
import { useBreakpoints, useStoreActions, useStoreState } from '../hooks';
import {
  PricePerSM,
  SoldUnits,
} from '../components/dashboard/kpis';
import { DashboardFilters } from '../components/dashboard/DashboardFilters';
import { PricePerSmCalcType } from '../components/TopBar/SettingsPopover';
import { SubscribePage } from '../components/SubscribePage/SubscribePage';
import { Sales } from '../components/dashboard/sales/Sales';

export const DashboardPage: React.FC = () => {
  const { isSmallScreen } = useBreakpoints();
  const { phaseId, projectId, isLoading, aggDate } = useStoreState(
    (state) => state.filters,
  );
  const developerId = useStoreState(
    (state) => state.user.profile?.developer_id,
  );
  const pricePerSmCalculation = useStoreState(
    (state) => state.user.profile?.price_per_sm_calculation as PricePerSmCalcType,
  );
  const { t } = useTranslation();
  const { fetchData } = useStoreActions((actions) => actions.dashboard);
  const { fetchTargets, fetchPlanData } = useStoreActions((actions) => actions.sales);
  const targets = useStoreState((state) => state.sales.targetsData?.projects.find((item) => item.project_id === projectId));
  const planData = useStoreState((state) => state.sales.planData?.projects.find((item) => item.project_id === projectId)?.sale_plan);
  const isDashboardEnabled = useStoreState((state) => state.user.profile?.dashboard_enabled);
  useEffect(() => {
    if (!isLoading && aggDate && projectId && phaseId) {
      fetchData({
        projectId,
        phaseId,
        date: aggDate,
      });
    }
  }, [fetchData, phaseId, projectId, isLoading, aggDate, developerId, pricePerSmCalculation]);

  useEffect(() => {
    if (!targets) {
      fetchTargets();
    }
  }, [fetchTargets, isLoading, targets]);

  useEffect(() => {
    if (!planData) {
      fetchPlanData();
    }
  }, [fetchPlanData, isLoading, planData]);

  const breakpoints = {
    xs: 24,
    lg: 6,
  };

  return (
    <Page
      pageClassname='dashboard'
      title={!isSmallScreen && t('dashboard.title')}
      filters={isDashboardEnabled && <DashboardFilters />}
    >
      {isDashboardEnabled ? (
        <Row className='dashboard-container' gutter={[16, 16]}>
        <Col {...breakpoints}>
          <SoldUnits />
        </Col>
        <Col {...breakpoints}>
          <PricePerSM />
        </Col>
        <Col {...breakpoints}>
          <Sales targetsData={targets} planData={planData} type='plan' />
        </Col>
        <Col {...breakpoints}>
          <Sales targetsData={targets} planData={planData} type='targets' />
        </Col>
        <Col xs={24} lg={12}>
          <SaleProgress />
        </Col>
        <Col xs={24} lg={12}>
          <Price />
        </Col>
        <Col xs={24} lg={12} xl={8}>
          <FloorOverview />
        </Col>
        <Col xs={24} lg={12} xl={8}>
          <SaleOverview />
        </Col>
        <Col xs={24} lg={12} xl={8}>
          <SaleDetail />
        </Col>
        </Row>
        ) : (
          <SubscribePage featureType='dashboard' />
        )}
    </Page>
  );
};
