import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from '../../../hooks';
import { MarketProjectExtended, Mode } from '../../../store/types';
import { IncludeIcon } from '../../market/IncludeIcon';

type Props = Pick<MarketProjectExtended, 'project_id' | 'include'>;

export const Footer: FC<Props> = ({ project_id }) => {
  const { t } = useTranslation();
  const { simplifiedProjects } = useStoreState((state) => state.market);
  const isIncluded =
    simplifiedProjects.find((project) => project.project_id === project_id)
      ?.include ?? false;
  const handleInclude = useStoreActions(
    (actions) => actions.market.handleInclude,
  );
  const mode = useStoreState((state) => state.market.mode);
  const handleClick = (): void => {
    handleInclude([project_id]);
  };

  return [Mode.CREATE, Mode.EDIT].includes(mode) ? (
    <div className='market-project-detail-footer'>
      <span className='text'>{t('market.projects.table.include')}</span>
      <IncludeIcon value={!!isIncluded} onClick={handleClick} />
    </div>
  ) : null;
};
