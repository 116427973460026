import { FC, useEffect, useState, useRef } from 'react';
import {
  ChevronDownIcon,
  DocumentTextIcon,
  DocumentPlusIcon,
} from '@heroicons/react/24/outline';
import { Select, Checkbox, Button } from 'antd';
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Paths } from '../../routes/Paths';
import styles from './ExportDropdown.module.css';
import { useStoreState } from '../../hooks';
import { ProjectType } from '../../store/types';
import { CountryEnum } from '../../api/enums';
import { ColumnSelector } from '../columnSelector/ColumnSelector';

type Props = {
  type: 'priceList' | 'marketData';
  onDownload: (
    format: string,
    filters: { available: boolean; reserved: boolean; sold: boolean },
  ) => void;
};

export const ExportDropdown: FC<Props> = ({ type, onDownload }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [dontClose, setDontClose] = useState(false);
  const { projectType } = useStoreState((state) => state.filters);
  const isRent = projectType === ProjectType.RENT;
  const [format, setFormat] = useState('xlsx');
  const [filters, setFilters] = useState({
    available: true,
    reserved: true,
    sold: true,
  });
  const [showColumnsFilter, setShowColumnsfilter] = useState(false);

  const profile = useStoreState((state) => state.user.profile);
  const showRealpad =
    profile?.country === CountryEnum.CZECHIA ||
    profile?.country === CountryEnum.SLOVAKIA;

  const { pathname } = useLocation();
  const showMarketExport = pathname === Paths.MARKET_REPORTS;

  const options = [
    { value: 'xlsx', label: 'XLSX' },
    { value: 'csv', label: 'CSV' },
    ...(showRealpad && !showMarketExport
      ? [{ value: 'realpad', label: 'Realpad XLSX' }]
      : []),
  ];

  const debounceTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleFilterChange = (filterName: keyof typeof filters): void => {
    setFilters((prev) => ({ ...prev, [filterName]: !prev[filterName] }));
  };

  const handleFormatChange = (formatValue: string): void => {
    setFormat(formatValue);
    setDontClose(true);
  };

  const handleDownload = (): void => {
    onDownload(format, filters);
    setIsOpen(false);
  };

  const handleOnMouseLeave = (): void => {
    debounceTimeoutRef.current = setTimeout(() => {
      if (!dontClose) {
        setIsOpen(false);
      }
    }, 500); // 500ms debounce delay
  };

  const handleOnMouseEnter = (): void => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
      debounceTimeoutRef.current = null;
    }
  };

  useEffect(() => {
    setShowColumnsfilter(false);
    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, [isOpen]);

  return (
    <div className={styles.container} style={{ width: '20rem' }}>
      <div
        className={styles.dropdown}
        style={{ maxHeight: isOpen ? 'unset' : 30 }}
        onMouseLeave={handleOnMouseLeave}
        onMouseEnter={handleOnMouseEnter} // Clear debounce when mouse re-enters
        onMouseOver={() => setDontClose(false)}
        onFocus={() => setDontClose(false)}
      >
        <div
          className={styles.button}
          onClick={() => setIsOpen(!isOpen)}
          aria-label='button'
        >
          <ArrowDownTrayIcon className='w-9' />
          <span className={styles.buttonContent}>
            {type === 'priceList'
              ? t(
                  `price_list.${isRent ? 'download' : 'title'}`,
                  `${isRent ? 'Rent Roll' : 'Pricing'}`,
                )
              : t('market_data.title', 'Market Data')}
          </span>
          <ChevronDownIcon
            className={`w-6 text-[#bdbdbd] stroke-[4px] ${
              isOpen && 'rotate-180'
            }`}
          />
        </div>
        {isOpen && (
          <div className={styles.menu}>
            {showMarketExport && (
              <div className={styles.checkboxes}>
                <div className={`${filters.available ? '' : styles.unchecked}`}>
                  <Checkbox
                    checked={filters.available}
                    onChange={() => handleFilterChange('available')}
                  >
                    {t('market.export.filter.available', 'Available')}
                  </Checkbox>
                </div>
                <div className={`${filters.reserved ? '' : styles.unchecked}`}>
                  <Checkbox
                    checked={filters.reserved}
                    onChange={() => handleFilterChange('reserved')}
                  >
                    {t('market.export.filter.reserved', 'Reserved')}
                  </Checkbox>
                </div>
                <div className={`${filters.sold ? '' : styles.unchecked}`}>
                  <Checkbox
                    checked={filters.sold}
                    onChange={() => handleFilterChange('sold')}
                  >
                    {t('market.export.filter.sold', 'Sold')}
                  </Checkbox>
                </div>
              </div>
            )}
            <div
              className='formatSelect'
              style={{
                display: 'flex',
                marginBottom: '5px',
                borderBottom: '1px solid #bdbdbd',
                paddingLeft: '5px',
                paddingBottom: '2px',
              }}
            >
              <DocumentTextIcon strokeWidth={2} className='w-7' />
              <Select
                className='formatSelectDropdown'
                bordered={false}
                value={format}
                defaultValue='xlsx'
                onChange={handleFormatChange}
                options={options}
                popupClassName='formatSelectDropdownPopup'
                suffixIcon={
                  <ChevronDownIcon className='w-5 text-[#bdbdbd] stroke-[4px]' />
                }
              />
            </div>
            {showMarketExport && (
              <>
                <Button
                  type='text'
                  onClick={() => {
                    setShowColumnsfilter((prev) => !prev);
                  }}
                  className='flex mb-[5px] pl-[5px] pb-[2px] w-full'
                >
                  <DocumentPlusIcon
                    strokeWidth={2}
                    className='w-7'
                    style={{ marginRight: 7, marginTop: 2 }}
                  />
                  <ColumnSelector showColumnsFilter={showColumnsFilter} />
                </Button>
                <div className='flex border-b-[1px] border-b-[#bdbdbd] mb-[5px]' />
              </>
            )}
            <Button
              className={styles.downloadButton}
              onClick={handleDownload}
              type='text'
              icon={
                <ArrowDownTrayIcon
                  className='w-7'
                  style={{ marginRight: 7, marginTop: 2 }}
                />
              }
            >
              {t('header.download', 'Download')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
