import { Segmented } from 'antd';
import { SegmentedValue } from 'antd/lib/segmented';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useBreakpoints,
  useRegionFormatting,
  useStoreActions,
  useStoreState,
} from '../../hooks';
import { OverviewLoading } from '../OverviewLoading';
import { ProjectSelect } from '../ProjectSelect';
import { PriceListExportButton } from '../priceList/PriceListExportButton';
import { ProjectType } from '../../store/types';

enum ColumnSwitchValue {
  PRICE,
  PRICE_PER_SM,
}

export const PriceUpdateFilters: FC = () => {
  const { isSmallScreen } = useBreakpoints();
  const { t } = useTranslation();
  const showPricePerSM = useStoreState(
    (state) => state.priceList.showPricePerSM,
  );
  const { areaUnit } = useRegionFormatting();
  const setShowPricePerSM = useStoreActions(
    (actions) => actions.priceList.setShowPricePerSM,
  );
  const handleChange = (value: SegmentedValue): void => {
    setShowPricePerSM(value === ColumnSwitchValue.PRICE_PER_SM);
  };
  const { projectType } = useStoreState((state) => state.filters);
  const isRent = projectType === ProjectType.RENT;
  return (
    <>
      <div>
        <Segmented
          value={
            showPricePerSM
              ? ColumnSwitchValue.PRICE_PER_SM
              : ColumnSwitchValue.PRICE
          }
          options={[
            {
              value: ColumnSwitchValue.PRICE,
              label: t(`common.${isRent ? 'rent' : 'price'}`, `${isRent ? 'Rent' : 'Price'}`),
            },
            {
              value: ColumnSwitchValue.PRICE_PER_SM,
              label: t(`common.${isRent ? 'rent' : 'price'}_per_unit`, `${isRent ? 'Rent' : 'Price'} per {{unit}}`, {
                unit: areaUnit,
              }),
            },
          ]}
          onChange={handleChange}
        />
      </div>
      <div>
        <OverviewLoading>
          <ProjectSelect isPriceUpdate />
        </OverviewLoading>
      </div>
      {!isSmallScreen && (
        <div>
          <PriceListExportButton />
        </div>
      )}
    </>
  );
};
