/* eslint-disable jsx-a11y/no-static-element-interactions */
/** @jsxImportSource @emotion/react */
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Form,
  FormItemProps,
  FormProps,
  Input,
  Row,
  Tag,
} from 'antd';
import type { CheckableTagProps, TagProps } from 'antd/es/tag';
import { LockClosedIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from '../../../../hooks';
import { Mode } from '../../../../store/types';
import { styles } from './styles';
import {
  MarketTypeEnum,
  BuildingConditionEnum,
  CountryEnum,
  ProjectTypeEnum,
  ConstructionTypeEnum,
} from '../../../../api/enums';
import { getCountryCode } from '../../../../utils/utils';
import { MarketIcon } from './icons/MarketIcon';

const { Item } = Form;
const { CheckableTag } = Tag;

const AddReportFormItem: FC<FormItemProps> = (props) => (
  <Item colon={false} labelAlign='left' {...props} />
);

const AddReportFormItemSelect: FC<
  CheckableTagProps & Pick<TagProps, 'icon'>
> = ({ children, ...props }) => (
  <CheckableTag
    css={!props.checked ? styles.selector : styles.checkedSelector}
    {...props}
  >
    {children}
  </CheckableTag>
);

interface AddReportForm {
  name: string;
  city: string[];
  market_type: MarketTypeEnum;
  include_new_projects: boolean;
}

interface AddReportProps {
  markets: MarketTypeEnum[];
  setMarkets: Dispatch<SetStateAction<MarketTypeEnum[]>>;
}

export const AddReport: FC<AddReportProps> = ({ markets, setMarkets }) => {
  const {
    mode,
    addReportStep: activeStep,
    primaryParameters,
  } = useStoreState((state) => state.market);
  const {
    overviewData,
    constructionType: initialConstructionType,
    unitsType,
  } = useStoreState((state) => state.filters);
  const [includeNewProjects, setIncludeNewProjects] = useState(
    primaryParameters?.include_new_projects ?? true,
  );
  const [buildingConditions, setBuildingConditions] = useState<
    Array<BuildingConditionEnum>
  >([BuildingConditionEnum.NEW]);
  const [projectTypes, setProjectTypes] = useState(
    unitsType ?? ProjectTypeEnum.UNITS,
  );
  const [constructionType, setConstructionType] = useState(
    initialConstructionType ?? [
      ConstructionTypeEnum.NEW,
      ConstructionTypeEnum.RENOVATION,
    ],
  );

  const { setUnitsType, setConstructionType: setConstructionTypeGlobal } =
    useStoreActions((state) => state.filters);
  const user = useStoreState((state) => state.user.profile);
  const isSlovakOrCzech =
    user?.country === CountryEnum.SLOVAKIA ||
    user?.country === CountryEnum.CZECHIA;
  const setMode = useStoreActions((actions) => actions.market.setMode);
  const setActiveStep = useStoreActions(
    (actions) => actions.market.setAddReportStep,
  );
  const { resetPolygons, resetCircles } = useStoreActions(
    (actions) => actions.market,
  );
  const setPrimaryParameters = useStoreActions(
    (actions) => actions.market.setPrimaryParameters,
  );
  const setSecondaryParameters = useStoreActions(
    (actions) => actions.market.setSecondaryParameters,
  );
  const setProjects = useStoreActions((actions) => actions.market.setProjects);

  const secondaryReports = useStoreState(
    (state) => state.secondaryMarket.reports,
  );
  const { market_dashboard_frequency: frequency = 'daily' } =
    overviewData || {};
  const isStandardPlan =
    frequency === 'daily' || frequency === 'weekly' || frequency === null;

  const mappedPrimaryReports = (overviewData?.market_dashboards || []).map(
    (d) => ({
      name: d.name,
      reportType: MarketTypeEnum.PRIMARY,
      id: d.id,
    }),
  );
  const mappedSecondaryReports = (secondaryReports || []).map((d) => ({
    name: d.name,
    reportType: d.reportType,
    id: d.reportId,
  }));

  const secModalCheck =
    markets.includes(MarketTypeEnum.SECONDARY) ||
    markets.includes(MarketTypeEnum.RENTAL);

  useEffect(() => {
    if (activeStep === null) {
      // Initialize the first step
      setActiveStep(1);
    }
  }, [activeStep, setActiveStep]);

  const [form] = Form.useForm<AddReportForm>();
  const dashboardId = useStoreState(
    (state) => state.filters.paramsPM?.dashboardId,
  );
  const secondaryDashboardId = useStoreState(
    (state) => state.filters.paramsSM?.reportId,
  );
  const { t } = useTranslation();

  const handleMarketToggle = (checked: boolean, market: MarketTypeEnum): void =>
    checked
      ? setMarkets([...markets, market])
      : setMarkets((prevState) => prevState.filter((m) => m !== market));

  const handleCancel = (): void => {
    setPrimaryParameters(null);
    setSecondaryParameters(null);
    setActiveStep(null);
    setMode(Mode.READ);
  };

  const handleBuildingConditions = (value: BuildingConditionEnum): void => {
    if (buildingConditions.includes(value)) {
      if (buildingConditions.length === 1) {
        return;
      }
      setBuildingConditions((prev) => prev.filter((v) => v !== value));
    } else {
      setBuildingConditions((prev) => [...prev, value]);
    }
  };

  const handleProjectType = (value: ProjectTypeEnum): void => {
    if (projectTypes.includes(value)) {
      if (projectTypes.length === 1) {
        return;
      }
      setProjectTypes((prev) => prev.filter((v) => v !== value));
    } else {
      setProjectTypes((prev) => [...prev, value]);
    }
  };

  const handleConstructionType = (value: ConstructionTypeEnum): void => {
    if (constructionType.includes(value)) {
      if (constructionType.length === 1) {
        return;
      }
      setConstructionType((prev) => prev.filter((v) => v !== value));
    } else {
      setConstructionType((prev) => [...prev, value]);
    }
  };

  const handleSubmit: FormProps<AddReportForm>['onFinish'] = (values): void => {
    // Check if the user selected PRIMARY PROJECTS market type
    const hasPrimaryOption = markets.includes(MarketTypeEnum.PRIMARY);
    // Check if the user selected SECONDARY or RENTALS market type
    const hasSecondaryOptions =
      markets.includes(MarketTypeEnum.SECONDARY) ||
      markets.includes(MarketTypeEnum.RENTAL);

    if (hasPrimaryOption) {
      // Initialize params from the step 1
      setPrimaryParameters({
        dashboard_name: values.name,
        included_project_ids: [],
        remaining_project_ids: [],
        geometry: null,
        include_new_projects: includeNewProjects,
        markets: null,
      });
    } else {
      setPrimaryParameters(null);
      setProjects([]);
    }

    setUnitsType(projectTypes);
    setConstructionTypeGlobal(constructionType);

    const country = getCountryCode(user?.country);
    // If the user selects secondary market or rentals, continue to STEP 2
    // otherwise, skip and continue to STEP 3
    if (hasSecondaryOptions && user && (country === 'CZ' || country === 'SK')) {
      setSecondaryParameters({
        country,
        name: values.name,
        geometry: null,
        user_id: user.sub,
        market_types: markets,
        building_conditions: buildingConditions,
      });
    }
    setActiveStep(2);
    if (mode === Mode.EDITSTART) {
      setMode(Mode.EDIT);
    } else {
      setMode(Mode.CREATE);
      resetPolygons();
      resetCircles();
    }
  };

  useEffect(() => {
    const hasSecondaryOptions =
      markets.includes(MarketTypeEnum.SECONDARY) ||
      markets.includes(MarketTypeEnum.RENTAL);
    if (!hasSecondaryOptions) {
      setBuildingConditions([]);
    } else {
      setBuildingConditions([BuildingConditionEnum.NEW]);
    }
  }, [markets]);

  const reportName = markets.includes(MarketTypeEnum.PRIMARY)
    ? mappedPrimaryReports?.find((d) => d.id === dashboardId)?.name
    : mappedSecondaryReports?.find((d) => d.id === secondaryDashboardId)?.name;

  return (
    <div className='flex items-center relative h-full'>
      <Form
        className='border-[1px] border-[#BDBDBD] p-12 rounded-[25px] bg-white w-[750px]'
        form={form}
        data-cy='add-report-modal-step-1'
        onFinish={handleSubmit}
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 17 }}
      >
        <AddReportFormItem
          label={t('market.projects.add_report_form.name')}
          name='name'
          rules={[
            {
              required: true,
              validator: async (_, value) => {
                if (
                  [...mappedPrimaryReports, ...mappedSecondaryReports]
                    .map((r) => r.name)
                    .filter((name) => name !== reportName)
                    .includes(value) &&
                  mode !== Mode.EDITSTART
                ) {
                  throw new Error(t('market.validations.name_unique'));
                }
                if (!value) {
                  throw new Error(t('market.validations.name'));
                }
              },
            },
          ]}
          initialValue={mode === Mode.EDITSTART ? reportName : ''}
          required={false}
        >
          <Input
            css={styles.input}
            className='input max-w-[240px]'
            placeholder={t(
              'market.projects.add_report_form.name.placeholder',
              'Untitled',
            )}
          />
        </AddReportFormItem>
        {mode !== Mode.EDITSTART && (
          <AddReportFormItem
            name='market_type'
            label={t(
              'market.projects.add_report_form.markets.title',
              'Markets',
            )}
            rules={[
              {
                required: markets.length === 0,
                message: t(
                  'market.validations.market_type',
                  'It is required to pick at least one market type.',
                ),
              },
            ]}
            required={false}
          >
            <Checkbox.Group className='flex flex-wrap gap-y-4'>
              <AddReportFormItemSelect
                className='flex h-fit items-center'
                checked={markets.includes(MarketTypeEnum.PRIMARY)}
                onChange={
                  (checked) =>
                    handleMarketToggle(
                      checked,
                      MarketTypeEnum.PRIMARY,
                    ) /* eslint-disable react/jsx-curly-newline */
                }
              >
                <MarketIcon
                  market='primary'
                  disabled={!markets.includes(MarketTypeEnum.PRIMARY)}
                />
                <span>{t('reports.topbar.primary', 'Primary Projects')}</span>
              </AddReportFormItemSelect>
              {isSlovakOrCzech ? (
                <>
                  {isStandardPlan ? (
                    <>
                      <AddReportFormItemSelect
                        className='flex h-fit items-center'
                        checked={markets.includes(MarketTypeEnum.SECONDARY)}
                        onChange={(checked) =>
                          handleMarketToggle(checked, MarketTypeEnum.SECONDARY)
                        }
                      >
                        <MarketIcon
                          market='secondary'
                          disabled={!markets.includes(MarketTypeEnum.SECONDARY)}
                        />
                        <span>
                          {t('reports.topbar.secondary', 'Secondary')}
                        </span>
                      </AddReportFormItemSelect>
                      <AddReportFormItemSelect
                        className='flex h-fit items-center'
                        checked={markets.includes(MarketTypeEnum.RENTAL)}
                        onChange={(checked) =>
                          handleMarketToggle(checked, MarketTypeEnum.RENTAL)
                        }
                      >
                        <MarketIcon
                          market='rental'
                          disabled={!markets.includes(MarketTypeEnum.RENTAL)}
                        />
                        <span>{t('reports.topbar.rentals', 'Rentals')}</span>
                      </AddReportFormItemSelect>
                    </>
                  ) : (
                    <>
                      <div className='flex border border-gray-300 pl-3 pr-4 items-center rounded-full space-x-2 select-none mr-3 bg-[#F1F1F1] text-bmgray'>
                        <LockClosedIcon className='w-8 stroke-2' />
                        <span>
                          {t('reports.topbar.secondary', 'Secondary')}
                        </span>
                      </div>
                      <div className='flex border border-gray-300 pl-3 pr-4 items-center rounded-full space-x-2 select-none bg-[#F1F1F1] text-bmgray'>
                        <LockClosedIcon className='w-8 stroke-2' />
                        <span>{t('reports.topbar.rentals', 'Rentals')}</span>
                      </div>
                    </>
                  )}
                </>
              ) : null}
            </Checkbox.Group>
          </AddReportFormItem>
        )}
        {(((mode === Mode.EDITSTART || mode === Mode.EDIT) &&
          markets.includes(MarketTypeEnum.PRIMARY)) ||
          mode !== Mode.EDIT) && (
          <AddReportFormItem
            label={t(
              'market.projects.add_report_form.update_policy.title',
              'Include New Projects',
            )}
            name='update_policy'
            required={false}
          >
            <Checkbox.Group>
              <AddReportFormItemSelect
                checked={includeNewProjects}
                onClick={() => setIncludeNewProjects(true)}
              >
                <span>
                  {t(
                    'market.projects.add_report_form.update_policy.automatic',
                    'Automatically',
                  )}
                </span>
              </AddReportFormItemSelect>
              <AddReportFormItemSelect
                checked={!includeNewProjects}
                onClick={() => setIncludeNewProjects(false)}
              >
                <span>
                  {t(
                    'market.projects.add_report_form.update_policy.manual',
                    'Manually',
                  )}
                </span>
              </AddReportFormItemSelect>
            </Checkbox.Group>
          </AddReportFormItem>
        )}
        <AddReportFormItem
          label={t(
            'market.projects.add_report_form.construction.title',
            'Construction',
          )}
          name='construction'
          required={false}
        >
          <Checkbox.Group>
            <AddReportFormItemSelect
              className='flex h-fit items-center'
              checked={constructionType.includes(ConstructionTypeEnum.NEW)}
              onClick={() => handleConstructionType(ConstructionTypeEnum.NEW)}
            >
              <div
                className={`w-5 h-5 ml-2 rounded-full ${
                  constructionType.includes(ConstructionTypeEnum.NEW)
                    ? 'bg-[#5A72B1]'
                    : 'bg-[#9EB9DA80]'
                }`}
              />
              <span>{t('enums.new_construction', 'Newly built')}</span>
            </AddReportFormItemSelect>
            <AddReportFormItemSelect
              className='flex h-fit items-center'
              checked={constructionType.includes(
                ConstructionTypeEnum.RENOVATION,
              )}
              onClick={() =>
                handleConstructionType(ConstructionTypeEnum.RENOVATION)
              }
            >
              <div
                className={`w-5 h-5 ml-2 rounded-full ${
                  constructionType.includes(ConstructionTypeEnum.RENOVATION)
                    ? 'bg-[#5A72B1]'
                    : 'bg-[#9EB9DA80]'
                }`}
              />
              <span>{t('enums.renovation', 'Renovation')}</span>
            </AddReportFormItemSelect>
          </Checkbox.Group>
        </AddReportFormItem>
        <AddReportFormItem
          label={t('market.projects.add_report_form.type.title', 'Type')}
          name='type'
          required={false}
        >
          <Checkbox.Group>
            <AddReportFormItemSelect
              className='flex h-fit items-center'
              checked={projectTypes.includes(ProjectTypeEnum.UNITS)}
              onClick={() => handleProjectType(ProjectTypeEnum.UNITS)}
            >
              <div
                className={`w-5 h-5 ml-2 rounded-full ${
                  projectTypes.includes(ProjectTypeEnum.UNITS)
                    ? 'bg-[#5A72B1]'
                    : 'bg-[#9EB9DA80]'
                }`}
              />
              <span>{t('enums.units', 'Units')}</span>
            </AddReportFormItemSelect>
            <AddReportFormItemSelect
              className='flex h-fit items-center'
              checked={projectTypes.includes(ProjectTypeEnum.HOUSES)}
              onClick={() => handleProjectType(ProjectTypeEnum.HOUSES)}
            >
              <div
                className={`w-5 h-5 ml-2 rounded-full ${
                  projectTypes.includes(ProjectTypeEnum.HOUSES)
                    ? 'bg-[#5A72B1]'
                    : 'bg-[#9EB9DA80]'
                }`}
              />
              <span>{t('enums.houses', 'Houses')}</span>
            </AddReportFormItemSelect>
          </Checkbox.Group>
        </AddReportFormItem>
        {secModalCheck && (
          <AddReportFormItem
            label={t(
              'market.projects.add_report_form.secondary_age.title',
              'Secondary Age',
            )}
            name='secondary_age'
            required={false}
          >
            <Checkbox.Group>
              <AddReportFormItemSelect
                className='flex h-fit items-center'
                checked={buildingConditions.includes(BuildingConditionEnum.NEW)}
                onClick={() =>
                  handleBuildingConditions(BuildingConditionEnum.NEW)
                }
              >
                <div
                  className={`w-5 h-5 ml-2 rounded-full ${
                    buildingConditions.includes(BuildingConditionEnum.NEW)
                      ? 'bg-[#5A72B1]'
                      : 'bg-[#9EB9DA80]'
                  }`}
                />
                <span>{t('enums.new', 'New')}</span>
              </AddReportFormItemSelect>
              <AddReportFormItemSelect
                className='flex h-fit items-center'
                checked={buildingConditions.includes(
                  BuildingConditionEnum.UNKNOWN,
                )}
                onClick={() =>
                  handleBuildingConditions(BuildingConditionEnum.UNKNOWN)
                }
              >
                <div
                  className={`w-5 h-5 ml-2 rounded-full ${
                    buildingConditions.includes(BuildingConditionEnum.UNKNOWN)
                      ? 'bg-[#5A72B1]'
                      : 'bg-[#9EB9DA80]'
                  }`}
                />
                <span>{t('enums.old', 'Old')}</span>
              </AddReportFormItemSelect>
            </Checkbox.Group>
          </AddReportFormItem>
        )}
      </Form>
      <div className='absolute bottom-8 left-1/2 transform -translate-x-1/2'>
        <Row justify='space-between' className='w-auto gap-8'>
          <Button
            onClick={handleCancel}
            className='rounded-[25px] py-8 px-16 flex items-center'
          >
            {t('common.cancel', 'Cancel')}
          </Button>
          <Button
            className='rounded-[25px] py-8 px-16 flex items-center'
            type='primary'
            htmlType='submit'
            onClick={() => form.submit()}
          >
            {t('common.next', 'Next')}
          </Button>
        </Row>
      </div>
    </div>
  );
};
