import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Button, Image, Tooltip } from 'antd';
import { Link, generatePath } from 'react-router-dom';
import { ArrowsPointingOutIcon, ChevronDoubleRightIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { PriceListTableData } from '../../../api/mockData/priceListData';
import styles from './DetailPreviewCard.module.css';
import { Paths } from '../../../routes/Paths';
import exteriorIcon from './icons/exteriorIcon.svg';
import houseIcon from './icons/houseIcon.svg';
import layoutIcon from './icons/layoutIcon.svg';
import stackIcon from './icons/stackIcon.svg';
import increaseIcon from './icons/increaseIcon.svg';
import decreaseIcon from './icons/decreaseIcon.svg';
import { useStoreState, useRegionFormatting, useFlatLayout } from '../../../hooks';
import { getSafeSuffix } from '../../../utils/utils';
import { formatDifference } from '../../../utils';
import { AvailabilityBadge } from '../../AvailabilityBadge/AvailablityBadge';
import { ProjectType } from '../../../store/types';

type Props = {
  onHide?: () => void;
  showDetailButton?: boolean;
  className?: string[];
  data?: PriceListTableData;
  demandCard?: boolean
  topPriceChanges?: [string, number][];
}

type LabelProps = {
  title?: string | number | null;
  icon?: ReactNode;
  children?: ReactNode[];
  tooltipTitle?: string;
}

const orientationSides = ['S', 'N', 'W', 'E', 'SN', 'SW', 'SE', 'NW', 'NE', 'WE'];

const DetailLabel: FC<LabelProps> = ({ title, icon, children, tooltipTitle }) =>
  tooltipTitle ?
    <Tooltip title={tooltipTitle}>
      <div className={styles.label}>{icon}{title}{children}</div>
    </Tooltip>
    :
    <div className={styles.label}>{icon}{title}{children}</div>;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const DetailPreviewCard: FC<Props> = ({ showDetailButton = false, className, data, demandCard = false, topPriceChanges, onHide }) => {
  const [imageIndex, setImageIndex] = useState(0);
  const developerId = useStoreState(
    (state) => state.user.profile?.developer_id,
  );
  const { t } = useTranslation();
  const { formatAreaUnits, calculateVatPrice, formatCurrency } = useRegionFormatting();
  const { getLayoutAbbr } = useFlatLayout();
  const { projectType } = useStoreState((state) => state.filters);
  const isRent = projectType === ProjectType.RENT;

  useEffect(() => {
    if (data && imageIndex > data.images.length - 1) {
      setImageIndex(data.images.length - 1);
    }
  }, [imageIndex, data]);

  const handleChevron = useCallback((direction: 'prev' | 'next'): void => {
    if (data && data.images) {
      const lastIndex = data.images.length - 1;
      if (direction === 'prev') {
        setImageIndex(imageIndex > 0 ? imageIndex - 1 : lastIndex);
      }
      if (direction === 'next') {
        setImageIndex(imageIndex < lastIndex ? imageIndex + 1 : 0);
      }
    }
  }, [data, imageIndex]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key === 'ArrowLeft') {
        handleChevron('prev');
      } else if (event.key === 'ArrowRight') {
        handleChevron('next');
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [imageIndex, handleChevron]);

  if (!data) {
    return <div>Not Found</div>;
  }

  const {
    availability,
    layout,
    floor_area,
    exterior_area,
    floor,
    internal_id,
    images,
    insights,
    current_price,
    new_price,
    price_difference,
    orientation,
    id,
    floor_count,
  } = data;

  const isHouse = !!floor_count;

  const detailUrl = generatePath(Paths.PRICE_LIST_DETAIL, { unitId: id });

  const currentPrice = formatCurrency(calculateVatPrice(current_price, floor_area, exterior_area)) ?? 0;
  const recommendedPrice = formatCurrency(calculateVatPrice(new_price, floor_area, exterior_area)) ?? 0;
  // const currentPrice = showPricePerSM
  //   ? formatCurrency(calculateVatPrice(current_price_per_sm, floor_area, exterior_area)) ?? 0
  //   : formatCurrency(calculateVatPrice(current_price, floor_area, exterior_area)) ?? 0;
  // const recommendedPrice = showPricePerSM
  // ? formatCurrency(calculateVatPrice(new_price_per_sm, floor_area, exterior_area)) ?? 0
  // : formatCurrency(calculateVatPrice(new_price, floor_area, exterior_area)) ?? 0;
  const priceDifference = formatDifference(price_difference);

  const sides = insights.find(({ name }) => name === 'sides')?.value;
  const orientations = orientation.map((val) => t(`enums.world_sides.${val}`, val)).join(', ');
  const noise = insights.find(({ name }) => name === 'noise')?.value;

  return (
    <div className={clsx(styles.detailCard, className)}>
      <div className={styles.titleContainer}>
          <div className={styles.title}>
            <Link to={detailUrl} className={styles.lgText} style={{ marginRight: '1.25rem' }}>
              <div>{t('price_list.detail.title', { id: null })} {internal_id}</div>
            </Link>
            <AvailabilityBadge unitAvailability={availability} />
          </div>
          { onHide && (
            <Button className={styles.iconBtn} onClick={() => onHide()}>
              <ChevronDoubleRightIcon className='bm-icon w-8' />
            </Button>
          )}
          { showDetailButton && (
            <Link to={detailUrl}>
              <ArrowsPointingOutIcon className='bm-icon w-8' />
            </Link>
          )}
      </div>
      <div className={styles.content}>
        <div className={styles.labelContainer}>
          <DetailLabel icon={<img src={layoutIcon} className={styles.icon} alt='Layout' />} title={getLayoutAbbr(layout)} tooltipTitle={t('enums.house_parts.layout')} />
          <DetailLabel icon={<img src={houseIcon} className={styles.icon} alt='Floor area' />} title={formatAreaUnits(floor_area)} tooltipTitle={t('enums.house_parts.floor_area')} />
          <DetailLabel icon={<img src={exteriorIcon} className={styles.icon} alt='Exterior area' />} title={formatAreaUnits(exterior_area)} tooltipTitle={t('market.reports.size_comparison.switch.exterior_area')} />
          <DetailLabel icon={<img src={stackIcon} className={styles.icon} alt={isHouse ? 'Floor' : 'Floor count'} />} title={isHouse ? floor_count : floor} tooltipTitle={t(`enums.house_parts.${isHouse ? 'floor_count' : 'floor'}`)} />
        </div>
        {imageIndex < images.length && (
          <>
          <div className={styles.imagesWrapper}>
          {images.length > 1 && (
            <>
              <ChevronLeftIcon
                className={styles.chevronIconLeft}
                onClick={() => handleChevron('prev')}
              />
              <ChevronRightIcon
                className={styles.chevronIconRight}
                onClick={() => handleChevron('next')}
              />
            </>
          )}
          <Image
            preview={{
              mask: false,
            }}
            src={`https://built-mind-internaldata.s3.eu-central-1.amazonaws.com/${developerId}/resources/images/flats/${getSafeSuffix(
              images[imageIndex].toString(),
            )}`}
            alt='Floor plan'
            className={styles.image}
          />
          </div>
          {images.length > 1 && (
            <div className='flex w-full justify-center items-center space-x-2 px-6'>
              {images.map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index} onClick={() => setImageIndex(index)} className={`w-full max-w-[80px] h-2 cursor-pointer ${imageIndex === index ? 'bg-bmgray' : 'bg-bmlightgray'}`} />
              ))}
            </div>
          )}
          </>
        )}
        <div className={styles.pricesContainer}>
          <div className={styles.priceColumn}>
            <span className={styles.smallText}>{t(`detail_preview_card.${isRent ? 'rent' : 'price'}`)}</span>
            <div className={styles.price}>
              <span className={styles.lgText}>{currentPrice}</span>
            </div>
          </div>
          <div className={styles.divider} />
          <div className={styles.priceColumn}>
            {isRent ? (
              <span className={styles.smallText}>{t('detail_preview_card.recommended_rent')}</span>
            ) : (
              <Tooltip title={t('detail_preview_card.recommended_price_long')}>
                <span className={styles.smallText}>{t('detail_preview_card.recommended_price')}</span>
              </Tooltip>
            )}
            <div className={styles.price}>
              <span className={styles.lgText}>{recommendedPrice}</span>
              {priceDifference.includes('+') && (
                <span className={styles.smallText}><img src={increaseIcon} className={styles.smallIcon} alt='Decrease' />{priceDifference}</span>
              )}{priceDifference.includes('-') && (
                <span className={styles.smallText}><img src={decreaseIcon} className={styles.smallIcon} alt='Decrease' />{priceDifference}</span>
              )}
            </div>
          </div>
        </div>
        { demandCard && topPriceChanges ? (
          <div className={styles.footerColumn}>
            <span className={styles.footerTitle}>{t('detail_preview_card.demand.title')}</span>
            {topPriceChanges.map(([name, priceChange]) => {
              const priceChangePercentage = `${(priceChange * 100).toFixed(1)}%`;
                return (
                  orientationSides.includes(name) ? (
                    <DetailLabel key={name}>
                      <span className={priceChange < 0 ? styles.decreaseText : ''}>
                        <img src={priceChange > 0 ? increaseIcon : decreaseIcon} className={styles.smallIcon} alt='Increase' />
                        {priceChangePercentage}
                      </span>
                      <span className={styles.footerValue}>{t(`price_update.title.${name}`)} {t('price_update.title.orientation')}</span>
                    </DetailLabel>
                  ) : (
                    <DetailLabel key={name}>
                      <span className={priceChange < 0 ? styles.decreaseText : ''}>
                        <img src={priceChange > 0 ? increaseIcon : decreaseIcon} className={styles.smallIcon} alt='Increase' />
                        {priceChangePercentage}
                      </span>
                      <span className={styles.footerValue}>{t(`price_update.title.${name}`)}</span>
                    </DetailLabel>
                  )
                );
            })}
          </div>
        ) : (
          <div className={styles.footerColumn}>
          <span className={styles.footerTitle}>{t('detail_preview_card.insights.title')}</span>
            { sides && (
              <DetailLabel>
                <span>{`${t('detail_preview_card.sides')} /`}</span>
                <span className={styles.footerValue}>{sides}</span>
              </DetailLabel>
            )}
            { orientations && (
              <DetailLabel>
                <span>{`${t('detail_preview_card.orientation')} /`}</span>
                <span className={styles.footerValue}>{orientations}</span>
              </DetailLabel>
            )}
            { noise && (
              <DetailLabel>
                <span>{`${t('detail_preview_card.noise.title')} /`}</span>
                <span className={styles.footerValue}>{noise}</span>
              </DetailLabel>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
