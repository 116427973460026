import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, SelectProps } from 'antd';
import {
  ChevronDownIcon,
  MapIcon,
  MapPinIcon,
} from '@heroicons/react/24/outline';
import { useStoreState, useStoreActions } from '../../hooks';
import { OverviewLoading } from '../OverviewLoading';
import { SubareaEnum } from '../../api/enums';
import { getUpdateFrequencyDates, sortReportsByOrder } from '../../utils/utils';
import { SelectSearch } from '../SelectSearch/SelectSearch';

const { Option } = Select;

const AreaSelect: FC<SelectProps> = (props) => {
  const { overviewData, area, paramsPM, marketDates } = useStoreState(
    (state) => state.filters,
  );
  const { reportsOrder } = useStoreState((state) => state.market);
  const { setArea, setSelectedReportId } = useStoreActions(
    (actions) => actions.filters,
  );
  const { setSubarea } = useStoreActions((actions) => actions.dailyNews);
  const setParamsPM = useStoreActions((actions) => actions.filters.setParamsPM);
  const { t } = useTranslation();

  const handleChange = (areaId: number): void => {
    const currentDash = overviewData?.market_dashboards.find(
      (data) => data.id === areaId,
    );
    setArea(areaId);
    setSelectedReportId(areaId);
    setSubarea(SubareaEnum.ALL);
    if (currentDash) {
      const validDates = getUpdateFrequencyDates(
        currentDash?.dates,
        overviewData?.market_dashboard_frequency ?? null,
      );
      if (paramsPM) {
        setParamsPM({
          dashboardId: areaId,
          date: validDates[validDates.length - 1],
          name: currentDash.name,
        });
      }
    }
  };

  const cities = useMemo(() => {
    if (overviewData?.market_dashboards) {
      return overviewData?.market_dashboards.filter((city) => !!city.default);
    }
    return [];
  }, [overviewData?.market_dashboards]);

  useEffect(() => {
    if (!area && cities) {
      setArea(cities[0]?.id);
    }
  }, [area, cities, setArea]);

  useEffect(() => {
    const currentDash = overviewData?.market_dashboards.find(
      (data) => data.id === area,
    );
    if (currentDash) {
      const dates = getUpdateFrequencyDates(
        currentDash?.dates,
        overviewData?.market_dashboard_frequency ?? null,
      );
      setParamsPM({
        dashboardId: area ?? cities[0]?.id,
        date: dates[dates.length - 1],
        name: currentDash.name,
      });
    }
  }, []);

  return (
    <Select
      bordered={false}
      value={area}
      onChange={handleChange}
      suffixIcon={<ChevronDownIcon className='filters-chevron-icon' />}
      placeholder={t('header.area')}
      dropdownMatchSelectWidth={false}
      {...props}
    >
      {sortReportsByOrder(
        overviewData?.market_dashboards.filter((city) => !!city.default) ?? [],
        reportsOrder,
      ).map((v) => (
        <Option key={v.id} value={v.id}>
          {v.name}
        </Option>
      ))}
    </Select>
  );
};

export const DailyNewsAreaFilters: FC = () => (
  <>
    <MapIcon className='filters-icon' />
    <OverviewLoading>
      <span className='daily_news_area'>
        <AreaSelect />
      </span>
    </OverviewLoading>
  </>
);

const ReportSelect: FC<SelectProps> = (props) => {
  const { overviewData, selectedReportId } = useStoreState(
    (state) => state.filters,
  );
  const { setSelectedReportId } = useStoreActions((action) => action.filters);
  const { t } = useTranslation();

  const reports = useMemo(() => {
    if (overviewData?.market_dashboards) {
      return overviewData.market_dashboards;
    }
    return [];
  }, [overviewData?.market_dashboards]);

  const handleChange = (id: number): void => {
    setSelectedReportId(id);
  };

  useEffect(() => {
    if (!selectedReportId && reports) {
      setSelectedReportId(reports[0]?.id);
    }
  }, [selectedReportId, reports, setSelectedReportId]);

  return (
    <SelectSearch
      className='daily-news-reports-selector'
      rootClassName='text-left'
      bordered
      value={selectedReportId}
      onChange={handleChange}
      placeholder={t('header.area')}
      optionFilterProp='children'
      options={reports}
    />
  );
};

export const DailyNewsReportFilters: FC = () => (
  <div style={{ fontSize: '18px' }}>
    <OverviewLoading>
      <span className='daily_news_area'>
        <ReportSelect />
      </span>
    </OverviewLoading>
  </div>
);

export const DailyNewsSubareaFilter: FC<SelectProps> = (props) => {
  const { area } = useStoreState((state) => state.filters);
  const { setSubarea, setCurrentData } = useStoreActions(
    (actions) => actions.dailyNews,
  );
  const { subarea, data, partialData } = useStoreState(
    (state) => state.dailyNews,
  );
  const { isLoading } = useStoreState((state) => state.global);
  const [firstRender, setFirstRender] = useState(true);
  const { t } = useTranslation();

  const subareas = useMemo(
    () => [
      {
        title: 'all',
        value: SubareaEnum.ALL,
      },
      {
        title: 'city',
        value: SubareaEnum.CITY,
      },
      {
        title: 'outskirts',
        value: SubareaEnum.OUTSKIRT,
      },
    ],
    [],
  );

  useEffect(() => {
    if (area && subarea && data && partialData && !isLoading) {
      let newData = data;
      switch (subarea) {
        case SubareaEnum.OUTSKIRT:
          newData = {
            ...newData,
            graph_data: partialData.outskirts_only_graph_data,
          };
          setCurrentData(newData);
          break;
        case SubareaEnum.CITY:
          newData = {
            ...newData,
            graph_data: partialData.city_only_graph_data,
          };
          setCurrentData(newData);
          break;
        case SubareaEnum.ALL:
          newData = { ...data };
          setCurrentData(newData);
          break;
        default:
          setCurrentData(newData);
          break;
      }
    }
  }, [area, data, subarea, partialData, setCurrentData, isLoading]);

  useEffect(() => {
    if (firstRender) {
      setSubarea(SubareaEnum.CITY);
      setFirstRender(false);
    }
  }, [firstRender, setSubarea]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {area === 517 && subarea && partialData && data && !isLoading && (
        <div className='flex items-center'>
          <MapPinIcon className='filters-icon' />
          <Select
            bordered={false}
            value={subarea}
            onChange={setSubarea}
            placeholder={t('header.subarea')}
            dropdownMatchSelectWidth={false}
            className='ml-0'
            {...props}
          >
            {subareas.map((v) => (
              <Option key={v.title} value={v.value}>
                {t(`daily_news.filters.${v.title}`)}
              </Option>
            ))}
          </Select>
        </div>
      )}
    </>
  );
};
